<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        
        <div>
            <bread-crumbs :items='items' />
            <tabsComp :tabs="tabs" />
        
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-17px !important;`">
                <v-row class="mt-10">
                    <v-col
                    cols="12"
                    md="10"
                    sm="12">
                    <form autocomplete="off">
                        <v-row>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{ lang.chose_from_list }} {{lang.start_date.substr(0,10)}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{ lang.chose_from_list }} {{lang.end_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label></label>
                            <b-input-group style="margin-top:-5px">
                            <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getCurrentCards()">{{lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                        </v-row>
                    <b-input-group class="mt-3" style="display:none;">
                        <b-form-input placeholder="Start Date" type="date" style="border:1px solid #000;border-radius: 0;" autocomplete="off" v-model="sdate"></b-form-input>
                        <b-form-input placeholder="End Date"  type="date" style="border:1px solid #000" autocomplete="off" v-model="edate"></b-form-input>
                        <b-input-group-append style="border:1px solid #000">
                        <!-- <b-button variant="light" style="background:gold;"  @click="getCurrentCards()"><i class="fas fa-search"></i></b-button> -->
                        <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:black;color:#fff"  @click="getCurrentCards()">{{lang.search}}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    </form>
                    </v-col>
                    <!-- <v-col cols="12" md="4" sm="12" class="text-end" v-if="!this.$store.state.licenseType.isBill"> -->
                    <v-col cols="12" md="2" sm="12" class="text-end pt-7">
                    <b-button
                        @click="addCalendar()"
                        v-b-toggle.add_addHomeCalendar
                        class="btn-sm btn"
                        style="margin-top:7px;width:150px;background:transparent;border:none !important;margin-left:-7px !important;"
                        id="multiply-button" 
                        >
                        <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_booking}}</div>
                        
                        </b-button>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    style="display:none;"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="lang.search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-col cols="12" :style="`margin-top:11px !important;margin-${lang.lalgin}: 20px !important;`">
                    <h6 class="text-center backBlack" :style="`padding:10px;margin-${lang.lalgin}:20px;`">{{ lang.calendar_table }}</h6>
                </v-col>
                <v-col cols="12" class="md-3" v-for="(itmes,indexs) in tablerows" :key="indexs">
                    <v-simple-table style="margin-inline-end: 20px !important;">
                        <thead>
                            <tr>
                                <th class="backBlack text-center" colspan="12" v-if="$store.state.licenseType.cars">{{ itmes.date }}</th>
                                <th class="backBlack text-center" colspan="10" v-else>{{ itmes.date }}</th>
                            </tr>
                            <tr>
                                <th class="text-center" style="width:110px;border-right:1px solid #fff !important;">{{ lang.work_type }}</th>
                                <th class="text-center" style="width:110px;border-right:1px solid #fff !important;">{{ lang.customer_type }}</th>
                                <!-- <th class="text-center" style="width:80px;">التوقيت</th> -->
                                <th class="text-center" style="">{{lang.full_name}}</th>
                                <th class="text-center" style="width:90px;">{{lang.mobile}}</th>
                                <th class="text-center" style="width:90px;" v-if="$store.state.licenseType.cars">{{lang.plate_number}}</th>
                                <th class="text-center" style="width:90px;" v-if="$store.state.licenseType.cars">{{lang.model}}</th>
                                <!-- <th class="text-center">الوصف</th> -->
                                <th class="text-center" style="width:110px;">{{lang.employee_name}}</th>
                                <th class="text-center" style="width:50px;">{{ lang.advanced_payment }}</th>
                                <th class="text-center" style="width:50px;">{{lang.last_update_by}}</th>
                                <th class="text-center" style="width:50px;">{{lang.last_update_date}}</th>
                                <th class="text-center" style="border:1px solid #000 !important;width:140px;">{{lang.status}}</th>
                                <!-- <th class="text-center" style="border:2px solid #fff !important;width:100px;">اغلاق</th> -->
                                <!-- <th class="text-center" style="border:2px solid #fff !important;width:100px;">لم يحضر </th> -->
                                <!-- <th class="text-center" style="border:2px solid #fff !important;width:100px;">تأجيل 24 ساعة </th> -->
                                <!-- <th class="text-center" style="border:2px solid #fff !important;width:100px;">امر تشغيل</th> -->
                                <th class="text-center" style="color:#fff !important;background:blue !important;border:1px solid #000 !important;width:100px;width:70px;">{{lang.action}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in itmes.rows" :key="index">
                                <td class="text-center" :style="`border:1px solid #bbb !important;${item._ctype.bgColor}`">{{ item._ctype[`name_`+lang.langname] }}</td>
                                <td class="text-center" :style="`border:1px solid #bbb !important;${item.customerTypebgColor}`">{{ item._customer_type }}</td>
                                <!-- <td class="text-center">{{ item.start_time }}</td> -->
                                <td class="text-center">{{ item.full_name }}</td>
                                <td class="text-center">{{ item.mobile }}</td>
                                <td class="text-center" v-if="$store.state.licenseType.cars">{{ item.plate_number }}</td>
                                <td class="text-center" v-if="$store.state.licenseType.cars">{{ item.car_model }}</td>
                                <!-- <td class="text-center">{{ item.description }}</td> -->
                                <td class="text-center">{{ item.username }}</td>
                                <td class="text-center">{{ item.advanced_paid }}</td>
                                <td class="text-center">{{ item._last_update }}</td>
                                <td class="text-center">{{ item._last_update_date }}</td>
                                <td class="text-center" :style="`border:1px solid #bbb !important;${item._status.bgColor} !important`">{{ item._status[`name_`+lang.langname] }}</td>
                                <!-- <td class="text-center" @click="close(item.id)" :style="`cursor:pointer;border:1px solid #bbb !important;background:red !important;color:#fff !important`">اغلاق</td> -->
                                <!-- <td class="text-center" @click="notCome(item.id)" :style="`cursor:pointer;border:1px solid #bbb !important;background:blue !important;color:#fff !important`">لم يحضر </td> -->
                                <!-- <td class="text-center" @click="daily24(item.id)" :style="`cursor:pointer;border:1px solid #bbb !important;background:darkred !important;color:#fff !important`">تأجيل 24 ساعة </td> -->
                                <!-- <td class="text-center" :style="`cursor:pointer;border:1px solid #bbb !important;background:green !important;color:#fff !important`">أمر تشغيل </td> -->
                                <td class="text-center" @click="editCal(item.id)" v-b-toggle.edit_calendar :style="`cursor:pointer;border:1px solid #bbb !important;background:blue !important;color:#fff !important`">{{ lang.update }} </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    
                </v-col>
            </v-row>
        </div>
        <Footer />
        <addHomeCalendar ref="add_addHomeCalendar" />
        <addNewCardVue ref="addNewCardVue"/>
        <calendarChangeStauts ref="calendarChangeStauts"/>
        <editCalendar ref="editCalendar" />
    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import tabsComp from '@/components/tabsComp.vue'
import ExportsPrint from '@/components/exports-print.vue';
import axios from 'axios'
import addHomeCalendar from '@/components/addHomeCalendar.vue'
import addNewCardVue from '@/components/addNewCard.vue';
import calendarChangeStauts from '@/components/calendarChangeStauts.vue'
import editCalendar from '@/components/editCalendar.vue'
export default{
    components: {editCalendar,BreadCrumbs,HeaderPortrate,Footer,tabsComp,ExportsPrint,addHomeCalendar,addNewCardVue,calendarChangeStauts},
    data(){
        return {
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear()
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear()
            },
            tablerows: [],
            search: '',
        }
    },
    computed:{
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      sdate: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
      },
      edate: function() {
        return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
      },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      items:function() {
          return {
            text: this.lang.invoices,
            disabled: true,
            href: '/invoicelist/',
          }
      },
      tabs: function(){
        
        const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
           
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                // // console.log("value",license);
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                      if(alicense[value.perms[0]] || value.perms[0] == 'any')
                          t.push(value)
                    }
                }
            })
        
            return t;
      }
    },
    methods:{
        collectSearch(){
            return {
                module: 'calendar',
                elements: {
                    sdate: this.sdate,
                    edate: this.edate
                }
            }
        },
        editCal(id){
            this.$refs.editCalendar.calid = id
            this.$refs.editCalendar.getCurrentCards()
        },
        changeStatus(id,status){
            this.$refs.calendarChangeStauts.notes = ''
            this.$refs.calendarChangeStauts.id = id
            this.$refs.calendarChangeStauts.status = status
        },
        addNewCard(){
            this.$refs.addNewCardVue.customer
        },
        async close(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            let post = new FormData();
            post.append('type','closeCalendar');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            post.append('data[id]',id);
            post.append('data[status]',6);
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            )
            // console.log(response.data)
            if(response && response.data && response.data.results){
                //this.tablerows = response.data.results.data;
                this.getCurrentCards();
            }
        },
        async daily24(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            let post = new FormData();
            post.append('type','daily24HoursCal');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            post.append('data[id]',id);
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            )
            // console.log(response.data)
            if(response && response.data && response.data.results){
                //this.tablerows = response.data.results.data;
                this.getCurrentCards();
            }
        },
        async notCome(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            let post = new FormData();
            post.append('type','closeCalendar');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            post.append('data[id]',id);
            
            post.append('data[status]',4);
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            )
            // console.log(response.data)
            if(response && response.data && response.data.results){
                //this.tablerows = response.data.results.data;
                this.getCurrentCards();
            }
        },
        addCalendar(){
            this.$refs.add_addHomeCalendar.reset();
        },
        async getCurrentCards(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            let post = new FormData();
            post.append('type','getAllCalendar');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('auth',cook);
            post.append('data[start]',this.sdate);
            post.append('data[end]',this.edate)
            const response = await axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            )
            // console.log(response.data)
            if(response && response.data && response.data.results){
                this.tablerows = response.data.results.data;
            }
        }
    },
    created(){
        this.getCurrentCards();
    }
}

</script>